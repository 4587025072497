import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Stop The Bots So I Can Buy My Hardware - It's Not That Simple.",
  "date": "2020-11-11",
  "slug": "blog/stop-the-bots-so-i-can-buy-my-hardware-its-not-that-simple",
  "featuredImage": "./images/stop-the-bots.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`TLDR: "Just make bot protection work" is impossible`}</em></p>
    <p>{`For large companies it takes a large sum of money, decisions from numerous teams, and proper implementation in order to do it in a way that does not compromise customer trust.`}</p>
    <p>{`In security, almost everything flows through three terms. `}<strong parentName="p">{`Threat`}</strong>{`, `}<strong parentName="p">{`Risk`}</strong>{`, and `}<strong parentName="p">{`Vulnerability`}</strong>{`.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Threat:`}</strong>{` Anything that can exploit vulnerabilities.`}</li>
      <li parentName="ul"><strong parentName="li">{`Risk:`}</strong>{` Potential damage and loss.`}</li>
      <li parentName="ul"><strong parentName="li">{`Vulnerability:`}</strong>{` Weakness being exploited.`}</li>
    </ul>
    <p>{`In the case of botting:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Threat:`}</strong>{` Automated scripts taking stock before people that do not use these scripts are able to.`}</li>
      <li parentName="ul"><strong parentName="li">{`Risk:`}</strong>{` You lose brand trust if you do not remediate this issue.`}</li>
      <li parentName="ul"><strong parentName="li">{`Vulnerability:`}</strong>{` A hole in CloudFlare that lets 1 of 100 bots through, or no bot mitigation at all.`}</li>
    </ul>
    <p>{`So a company has two choices. Eat the risk and brand trust loss, or do something about it. If the cost of brand degradation is perceived to be less than the cost to mitigate this risk, it will continue. `}</p>
    <p>{`Next, if the company chooses to mitigate the risk, they need to decide how to. Since no platform will mitigate 100% of the risk, it is important to ensure that whatever you choose to do maintains:`}</p>
    <ol>
      <li parentName="ol">{`Your customer experience is still top notch (accessibility)`}</li>
      <li parentName="ol">{`Your customer data is secure and safe (confidentiality)`}</li>
      <li parentName="ol">{`Your customer is who they say they are (has integrity)`}</li>
    </ol>
    <p>{`Every platform you can choose has positives and negatives. For example, if you introduce captcha, you reduce customer satisfaction and potential sell through. If you don't, you will have one less way that bots are being stopped. Putting captcha in is also not a "bolt on" experience. Like Nvidia seems to have found out, it takes planning, testing, and correct implementation. A poorly implemented captcha can bring more harm to your brand trust than another launch mostly taken by bots.`}</p>
    <p>{`Introducing a queue system with validation like Best Buy's creates an interesting dynamic.`}</p>
    <ol>
      <li parentName="ol">{`You defeat bots by introducing a completely different system that they have to account for.`}</li>
      <li parentName="ol">{`You help to allow more opportunities for real people to get your product.`}</li>
      <li parentName="ol">{`You introduce potential errors and confusion.`}</li>
    </ol>
    <p>{`With the new validation and queue system, you reduce accessibility while improving integrity. It is harder to get through the steps, but it helps to ensure the people that are who they say they are will have the opportunity to buy a product.`}</p>
    <p>{`Hopefully, this can help as an explainer as to why it's not as cut and dry as "just slap a captcha on!"`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      